var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.loading)?_c('BaseLoading',{staticStyle:{"min-height":"400px","height":"90vh"}}):_c('div',{staticClass:"text-left"},[_c('BaseHeader',{attrs:{"title":'My Bids'}}),_c('div',{staticClass:"card mt-2 border-0 bg-white shadow"},[_c('div',{staticClass:"card-body text-right d-flex"},[_c('v-text-field',{staticClass:"my-auto",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.$store.state.search),callback:function ($$v) {_vm.$set(_vm.$store.state, "search", $$v)},expression:"$store.state.search"}})],1),(_vm.userType != _vm.userTypes.writer)?_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.bidsTableAdmin,"items":_vm.bids,"search":_vm.$store.state.search,"dense":""},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.order_id } }}},[_vm._v(_vm._s(item.order_id || ""))])]}},{key:"item.bids",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"table sub-table"},[_c('tbody',_vm._l((item.bids),function(bid,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(bid.create_stamp || ""))]),_c('td',[_c('router-link',{attrs:{"to":{
                      name: 'WriterProfile',
                      params: { id: bid.writer_id || '' },
                    }}},[_vm._v(_vm._s(bid.writer_name))])],1),_c('td',[_vm._v(_vm._s(bid.current_orders || 0)+" Current Orders")]),_c('td',[_vm._v(_vm._s(bid.writer_level || ""))]),_c('td',[_vm._v(" "+_vm._s(bid.writer_amount ? ("$" + (bid.writer_amount)) : "")+" ")]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"primary--text",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){_vm.$store.state.dialog = true;
                        _vm.activeBid = bid;
                        _vm.action = 'assign';}}},[_vm._v("Assign")]),_c('v-btn',{staticClass:"white--text ml-1",attrs:{"elevation":"0","fab":"","x-small":""},on:{"click":function($event){_vm.$store.state.dialog = true;
                        _vm.activeBid = bid;
                        _vm.action = 'delete';}}},[_c('v-icon',{staticClass:"error--text",attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)])])}),0)])]}}],null,true)}):_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.bidsTableHeaders,"items":_vm.bids,"search":_vm.$store.state.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn-toggle',[_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){_vm.$store.state.dialog = true;
                _vm.activeBid = item;
                _vm.action = 'cancel';}}},[_vm._v("Cancel")])],1)]}}],null,true)})],1)],1),_c('v-dialog',{staticClass:"text-left",attrs:{"max-width":"290","transition":"dialog-top-transition"},model:{value:(_vm.$store.state.dialog),callback:function ($$v) {_vm.$set(_vm.$store.state, "dialog", $$v)},expression:"$store.state.dialog"}},[_c('v-card',{attrs:{"loading":_vm.bidding}},[_c('v-card-title',{staticClass:"text-h5 text-capitalize"},[_vm._v(" Bid (#"+_vm._s(_vm.activeBid.id || "")+") ")]),_c('v-card-text',{staticClass:"text-left"},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.action)+" Bid "+_vm._s(_vm.action === "assign" ? ("to " + (_vm.activeBid.writer_name) + " id #" + (_vm.activeBid.writer_id)) : "")+" ")]),_vm._v(" Are you sure you want perform this action? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$store.state.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":_vm.action == 'assign' ? 'green darken-1' : 'error darken-1',"text":"","disabled":_vm.bidding},on:{"click":_vm.bidAction}},[_vm._v(" Yes, "+_vm._s(_vm.action)+" Bid ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }